<template>
  <div>
    <div>
      <b-card no-body class="">
        <b-card-text>
          <div class="d-flex justify-content-between p-2">
            <div class="c_ol-md-6">
              <h2>Liste des entreprises</h2>
            </div>
            <div class="c_ol-md-4">
              <b-form-group
                label-cols-sm="0"
                label-align-sm="left"
                label-size=""
                label-for="filterInput"
                class="ml-auto"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Rechercher nom..."
                  />
                  <b-input-group-append>
                    <b-button @click="filter = ''" class="btn btn-secondary">
                      Effacer
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </b-card-text>

        <div class="">
          <b-table
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :items="allEnterprises"
            responsive
            :fields="userTableColumns"
            show-empty
            :busy="busy"
            empty-text="Pas de collaborateur disponible"
            class="position-relative"
            hover
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Chargement...</strong>
              </div>
            </template>

            <template #cell(name)="data">
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }}
              </span>
            </template>

            <template #cell(collaborators_count)="data">
              <b-badge class="stat-badge" variant="primary"
                >{{ data.item.users_count }} /
                {{ maxCollaborators(data.item) }}</b-badge
              >
            </template>

            <template #cell(customers_count)="data">
              <b-badge class="stat-badge" variant="primary">{{
                data.item.customers_count
              }}</b-badge>
            </template>

            <template #cell(projects_count)="data">
              <b-badge class="stat-badge" variant="primary">{{
                data.item.projects_count
              }}</b-badge>
            </template>

            <template #cell(tasks_count)="data">
              <b-badge class="stat-badge" variant="primary">{{
                data.item.tasks_count
              }}</b-badge>
            </template>

            <template #cell(id)="row">
              <b-btn
                color="primary"
                variant="text"
                @click="
                  $router.push({
                    name: 'edit-enterprise',
                    params: { id: row.item.id },
                  })
                "
              >
                <feather-icon icon="EditIcon" size="20"></feather-icon>
              </b-btn>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-1 ml-1"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    FeatherIcon,
  },
  data() {
    return {
      busy: false,
      filter: "",
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      sortBy: "name",
      sortDesc: false,
      totalRows: 1,
      userTableColumns: [
        { key: "name", sortable: true, label: "Nom" },
        {
          key: "collaborators_count",
          label: "Nombre de collaborateurs",
          sortable: true,
        },
        { key: "customers_count", label: "Nombre de clients", sortable: true },
        {
          key: "projects_count",
          label: "Nombre de projets",
          sortable: true,
        },
        { key: "tasks_count", label: "Nombre de tâches", sortable: true },
        { key: "id", label: "Actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("enterprise", ["allEnterprises"]),
  },
  methods: {
    ...mapActions("enterprise", ["fetchAllEnterprises"]),

    exportToPdf() {
      axiosIns.get("absence-validation/export-pdf").then((r) => {
        window.open(r.data, "_blank");
      });
    },

    onFiltered(allEnterprises) {
      this.totalRows = allEnterprises.length;
      this.currentPage = 1;
    },

    maxCollaborators(value) {
      if (value.setting) {
        return value?.setting?.max_collaborators;
      } else {
        return 0;
      }
    },
  },

  mounted() {
    this.busy = true;
    this.fetchAllEnterprises().then(() => {
      this.busy = false;
      this.totalRows = this.allEnterprises.length;
    });
  },
};
</script>
